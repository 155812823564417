<template>
  <div class="controller-container">
    <div class="gpio">
      <template v-if="$store.state.use_lift">
        <button :class="['button_gpio']" @click="setLift('1')" >UP</button>
        <button :class="['button_gpio']" @click="setLift('0')" >DW</button>
      </template>
      <template v-if="$store.state.use_gpio">
        <button v-for="i in 8"
          :key="i"
          :class="['button_gpio', { 'active': !!selected[i-1] }]"
          @click="selectGpio(i-1)"
        >
          {{ i }}
        </button>
      </template>
    </div>
    <div class="cam" v-if="isUseCam">
      <video autoplay ref="video">
        <source :src="camUrl" type="video/webm">
      </video>
    </div>
    <div class="circle-left">
      <div class="btn-container-column">
        <div class="up">
          <v-icon
            @mousedown="goToFront"
            @touchstart="goToFront"
            @mouseup="stopMoving('lin')"
            @touchend="stopMoving('lin')"
          >
            mdi-chevron-up
          </v-icon>
        </div>
        <hr class="hr-left">
        <div class="down">
          <v-icon
            @mousedown="goToBack"
            @touchstart="goToBack"
            @mouseup="stopMoving('lin')"
            @touchend="stopMoving('lin')"
          >
            mdi-chevron-down
          </v-icon>
        </div>
      </div>
    </div>
    <template v-if="showDockBtn&& $store.state.use_docking">
      <div class="btn-dock">
        <button @click="doDocking">
          {{ markerId }}번으로 도킹
        </button>
      </div>
    </template>
    <template v-if="showDockingBtn">
      <div class="btn-docking">
        <button @click="cancelMoving">
          도킹중 X
        </button>
      </div>
    </template>
    <div class="circle-right">
      <div class="btn-container-row">
        <div class="left">
          <v-icon
            @mousedown="turnToLeft"
            @touchstart="turnToLeft"
            @mouseup="stopMoving('ang')"
            @touchend="stopMoving('ang')"
          >
            mdi-chevron-left
          </v-icon>
        </div>
        <hr class="hr-right">
        <div class="right">
          <v-icon
            @mousedown="turnToRight"
            @touchstart="turnToRight"
            @mouseup="stopMoving('ang')"
            @touchend="stopMoving('ang')"
          >
            mdi-chevron-right
          </v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import EventBus from '@/main.js';

export default {
  name: 'Controller',
  data() {
    return {
      socketData: {
        linear: {
          x: 0,
          y: 0,
          z: 0,
        },
        angular: {
          x: 0,
          y: 0,
          z: 0
        }
      },
      showDockingBtn: false,
      showDockBtn: false,
      markerId: null,
      selected: [0, 0, 0, 0, 0, 0, 0, 0],
      camUrl: '/ros/cam',
      isUseCam: false,
    }
  },
  created() {
    this.initialize();
    this.isValidCam();
  },
  beforeDestroy() {
    this.stopSocket();
    EventBus.$emit('socketEmit', 'robot:isJog', false);
    if(this.isUseCam){
      this.camUrl='';
      this.$refs['video'].load();
      this.$refs['video'].pause();
      this.$refs['video'].remove();
    }

    EventBus.$off('docking:available/controller');
    EventBus.$off('docking/controller');
    EventBus.$off('robot:gpio/controller');
  },
  computed: {
    model(){
      return this.$store.state.model;
    },
    jog_front_speed(){
      return this.$store.state.jog_front_speed;
    },
    jog_back_speed(){
      return this.$store.state.jog_back_speed;
    },
    jog_rotation_speed(){
      return this.$store.state.jog_rotation_speed;
    },
  },
  methods: {
    initialize() {
      EventBus.$emit('socketEmit', 'robot:isJog', true);

      EventBus.$off('docking:available/controller');
      EventBus.$off('docking/controller');
      EventBus.$off('robot:gpio/controller');

      EventBus.$on('docking:available/controller', (data)=> {
        if(data){
          const markers = JSON.parse(data);
          if(this.$store.state.only_home_docking&& markers[0].id!== 0) return;
          this.markerId = markers[0].id;
          if(!this.showDockingBtn) this.showDockBtn = true;
        }else{
          this.markerId = null;
          this.showDockBtn = false;
        }
      });

      EventBus.$on('docking/controller', ()=> {
        this.markerId = null;
        this.showDockingBtn = false;
      });

      EventBus.$on('robot:gpio/controller', (data)=> {
        this.selected = [
          data.gpio['Output0'],
          data.gpio['Output1'],
          data.gpio['Output2'],
          data.gpio['Output3'],
          data.gpio['Output4'],
          data.gpio['Output5'],
          data.gpio['Output6'],
          data.gpio['Output7'],
        ];
      });
    },
    emitSocket() {
      EventBus.$emit('socketEmit', 'robot:cmd_velocity', this.socketData);
    },
    stopSocket() {
      EventBus.$emit('socketEmit', 'robot:cmd_velocity', {
        linear: {
          x: 0,
          y: 0,
          z: 0,
        },
        angular: {
          x: 0,
          y: 0,
          z: 0
        }
      });
    },
    isValidCam() {
      this.$axios.get(`/ros/isValidCam`)
        .then((res) => {
          if (res.status === 200 && res.data) this.isUseCam = true;
        })
        .catch(err => { throw Error(err); });
    },
    stopMoving(dir) {
      if (dir === 'lin' && this.socketData.angular.z) {
        this.socketData.linear.x = 0;
        this.stopSocket();
        this.emitSocket();
      } else if (dir === 'ang' && this.socketData.linear.x) {
        this.socketData.angular.z = 0;
        this.stopSocket();
        this.emitSocket();
      } else {
        this.socketData = {
          linear: {
            x: 0,
            y: 0,
            z: 0,
          },
          angular: {
            x: 0,
            y: 0,
            z: 0
          }
        };
        this.stopSocket();
      }
    },
    goToFront() {
      if (this.socketData.angular.z) this.stopSocket();
      this.socketData.linear.x = this.getFrontSpeed();
      this.emitSocket();
    },
    goToBack() {
      if (this.socketData.angular.z) this.stopSocket();
      this.socketData.linear.x = this.getBackSpeed();
      this.emitSocket();
    },
    turnToLeft() {
      if (this.socketData.linear.x) this.stopSocket();
      this.socketData.angular.z = this.getLeftSpeed();
      this.emitSocket();
    },
    turnToRight() {
      if (this.socketData.linear.x) this.stopSocket();
      this.socketData.angular.z = this.getRightSpeed();
      this.emitSocket();
    },
    getFrontSpeed() {
      return this.jog_front_speed;
    },
    getBackSpeed() {
      return -this.jog_back_speed;
    },
    getLeftSpeed() {
      return this.jog_rotation_speed;
    },
    getRightSpeed() {
      return -this.jog_rotation_speed;
    },
    cancelMoving() {
      this.showDockBtn = false;
      this.showDockingBtn = false;
      return this.$axios.put('/nav/cancel');
    },
    doDocking() {
      this.showDockBtn = false;
      this.showDockingBtn = true;
      return this.$axios.put(`/nav/docking/${this.markerId}`)
        .then((res) => {
          if (res.status === 200) { EventBus.$emit('offController'); }
        })
        .catch(err => { throw Error(err); });
    },
    selectGpio(index) {
      this.$axios.put(`/robot/output/${index}/${!this.selected[index] ? 1 : 0}`).catch(err => { throw Error(err); });
    },
    setLift(flag) {
      console.log('Auto_Move_cmd flag:', flag);
      this.$axios.post('/ros/service', JSON.parse(`{"serviceName": "/${this.$store.state.robot_namespace}/Auto_Move_cmd", "serviceType": "tetraDS_interface/lift_auto_movement", "options": {"start": ${flag}}}`))
        .then(res => {
          if (res.status === 200) {
            console.log('Auto_Move_cmd exe:', flag);
          }
        })
        .catch(err => console.log('Auto_Move_cmd err:', err));
    },
  }
}
</script>

<style scoped>

.circle-left {
  position: absolute;
  z-index: 9998;
  top: 65%;
  left: 15%;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-color: rgba(39, 39, 39, 0.5);
}

.cam{
  position: absolute;
  z-index: 9980;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cam video{
  width: 500px;
  border-radius: 15px;
}

.gpio{
  position: absolute;
  z-index: 9997;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button_gpio{
  margin: 3px;
  width: 70px;
  height: 70px;
  background-color: #777777;
  border-color: #773939;
  border-style:solid;
  border-width:5px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}

.v-icon {
  cursor: pointer;
  font-size: 50px;
}

.active {
  background-color: #AA3939;
}


.btn-dock {
  position: absolute;
  z-index: 9990;
  top: 85%;
  left: 35%;
  height: 10%;
  width: 30%;
  border-radius: 10px;
  background-color: #00BFB4;
  color: #fff;
  text-align: center;
  font-size: 3rem;
  box-shadow: 5px 5px 5px 5px #222222;
}

.btn-docking {
  position: absolute;
  z-index: 9990;
  top: 85%;
  left: 35%;
  height: 10%;
  width: 30%;
  border-radius: 10px;
  background-color: #b34d49;
  color: #fff;
  text-align: center;
  font-size: 3rem;
  animation-duration: 1s;
  animation-name: blink;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.circle-right {
  position: absolute;
  z-index: 9998;
  top: 65%;
  right: 15%;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-color: rgba(39, 39, 39, 0.5);
}

.btn-container-column {
  position: absolute;
  top: 5%;
  left: 35%;
  height: 90%;
  width: 30%;
  border-radius: 15px;
  background: #fff;
  text-align: center;
}

.btn-container-row {
  position: absolute;
  top: 35%;
  left: 5%;
  height: 30%;
  width: 90%;
  border-radius: 15px;
  background: #fff;
}

.up {
  height: 50%;
}

.down {
  height: 49%;
}

.down > .v-icon {
  top: 43%;
  bottom: 0 !important;
}

.btn-container-row .v-icon {
  display: inherit;
}

.left {
  display: inline-block;
  height: 100%;
  width: 50%;
}

.right {
  display: inline-block;
  height: 100%;
  width: 48%;
}

.right > .v-icon {
  left: 45%;
  bottom: 0 !important;
}

.hr-left {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  border: thin solid #edebeb;
}

.hr-right {
  display: inline-block;
  height: 50%;
  width: 0;
  vertical-align: middle;
  border: thin solid #edebeb;
}

@media (max-width: 1024px) {
  .button_gpio{
    margin: 3px;
    width: 40px;
    height: 40px;
    background-color: #777777;
    border-color: #773939;
    border-style:solid;
    border-width:5px;
    color: white;
    border-radius: 10px;
    cursor: pointer;
  }

  .btn-dock {
    position: absolute;
    z-index: 9990;
    top: 85%;
    left: 35%;
    height: 10%;
    width: 30%;
    border-radius: 10px;
    background-color: #00BFB4;
    color: #fff;
    text-align: center;
    font-size: 1rem;
    box-shadow: 5px 5px 5px 5px #222222;
  }

  .btn-docking {
    position: absolute;
    z-index: 9990;
    top: 85%;
    left: 35%;
    height: 10%;
    width: 30%;
    border-radius: 10px;
    background-color: #b34d49;
    color: #fff;
    text-align: center;
    font-size: 1rem;
    animation-duration: 1s;
    animation-name: blink;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  .circle-left {
    position: absolute;
    z-index: 9998;
    top: 55%;
    left: 15%;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-color: rgba(39, 39, 39, 0.5);
  }

  .circle-right {
    position: absolute;
    z-index: 9998;
    top: 55%;
    right: 15%;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-color: rgba(39, 39, 39, 0.5);
  }

  .cam video{
    width: 250px;
    border-radius: 15px;
  }

  .v-icon {
    cursor: pointer;
    font-size: 35px;
  }

  .active {
    background-color: #AA3939;
  }
}

@keyframes blink{
  from {
    opacity: 1;
  }

  to {
    opacity: 0.5;
  }
}

</style>
