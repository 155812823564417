<template v-if="$store.state.use_head">
  <v-col
    id="header"
    class="ma-1"
    cols="1"
    :style="{ minWidth: '50px', width: '100%', height: '85vh', borderRadius: '5px', border: '2px solid #686868', padding: '0', margin: '0', overflow: 'auto'}"
  >
    <controller-component
      v-if="showController"
    />
    <v-col
      :style="{ padding: '0', margin: '0'}"
    >
      <v-col
        cols="auto config"
        class="text-md-center"
        v-if="config"
      >
        <router-link
          v-slot="{ navigate }"
          custom
          to="/config"
          replace
        >
          <div
            role="link"
            @click="navigate"
          >
            <v-icon
              color="white darken-2 "
            >
              mdi-cog-outline
            </v-icon>
          </div>
        </router-link>
      </v-col>
      <v-col
        v-if="home"
        cols="auto config"
        class="text-md-center"
        @click="$router.replace('/')"
      >
        <v-icon
          color="white darken-2 "
        >
          mdi-home-outline
        </v-icon>
      </v-col>
      <template v-if="showButtons">
        <v-col cols="auto control" class="text-md-center" v-if="$store.state.use_head_button_open">
          <v-icon
            color="white darken-2 "
            @mousedown="showButtons=false"
          >
            mdi-menu-down
          </v-icon>
        </v-col>
        <template v-if="$store.state.use_head_button_void">
          <template v-if="showList">
            <v-col cols="auto control" class="text-md-center" v-if="goto">
              <v-icon
                color="white darken-2 "
                @mousedown="toggleList"
              >
                mdi-view-list
              </v-icon>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="auto control" class="text-md-center" v-if="goto">
              <v-icon
                color="white darken-2 "
                @mousedown="toggleList"
              >
                mdi-view-list-outline
              </v-icon>
            </v-col>
          </template>
        </template>
        <template v-if="$store.state.use_head_button_servo">
          <template v-if="motorOn">
            <v-col cols="auto control" class="text-md-center" v-if="servo">
              <v-icon
                color="white darken-2 "
                @mousedown="motorHandler"
              >
                mdi-car-off
              </v-icon>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="auto control" class="text-md-center" v-if="servo">
              <v-icon
                color="white darken-2 "
                @mousedown="motorHandler"
              >
                mdi-car-electric
              </v-icon>
            </v-col>
          </template>
        </template>
        <template v-if="$store.state.use_head_button_jog">
          <v-col cols="auto control" class="text-md-center" v-if="controller">
            <v-icon
              color="white darken-2 "
              @mousedown="showControllerHandler"
            >
              mdi-gamepad-outline
            </v-icon>
          </v-col>
        </template>
        <template v-if="$store.state.use_head_button_reload">
          <v-col cols="auto control" class="text-md-center" v-if="reload">
            <v-icon
              color="white darken-2 "
              @mousedown="refreshApp"
            >
              mdi-wifi-refresh
            </v-icon>
          </v-col>
        </template>
        <template v-if="$store.state.use_head_button_estimate">
          <template v-if="showFinding">
            <v-col cols="auto control" class="text-md-center" v-if="setPose">
              <v-icon
                color="green darken-2 "
                @mousedown="findLocation"
              >
                mdi-crosshairs-gps
              </v-icon>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="auto control" class="text-md-center" v-if="setPose">
              <v-icon
                color="white darken-2 "
                @mousedown="findLocation"
              >
                mdi-crosshairs-gps
              </v-icon>
            </v-col>
          </template>
        </template>
      </template>
      <template v-else>
        <v-col cols="auto control" class="text-md-center" v-if="$store.state.use_head_button_open">
          <v-icon
            color="white darken-2 "
            @mousedown="showButtons=true"
          >
            mdi-menu-open
          </v-icon>
        </v-col>
      </template>
    </v-col>

  </v-col>
</template>

<script>
  import EventBus from "@/main";
  import ControllerComponent from "@/components/controller/controller.vue";

  export default {
    name: 'DefaultHeader',
    components: {ControllerComponent},
    props: {
      home: {
        type: Boolean,
        default: true,
      },
      config: {
        type: Boolean,
        default: false,
      },
      goto: {
        type: Boolean,
        default: false,
      },
      servo: {
        type: Boolean,
        default: true,
      },
      controller: {
        type: Boolean,
        default: true,
      },
      showController: {
        type: Boolean,
        default: false,
      },
      reload: {
        type: Boolean,
        default: true,
      },
      setPose: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showButtons: false,
        motorOn: true,
        showFinding: false,
        showList: true,
      };
    },
    created(){
      EventBus.$on('offController', this.offController);
      EventBus.$on('toggleController', this.toggleController);
      EventBus.$on('offFinding', () => {
        this.showFinding = !this.showFinding;
      });

      this.initialize();
    },
    beforeDestroy() {
      EventBus.$off('offController');
      EventBus.$off('toggleController');
      EventBus.$off('offFinding');
    },
    methods: {
      initialize() {
        if(!this.$store.state.use_head_button_open) this.showButtons= true;
        return this.getServoMotorState()
          .catch(err => {
            throw Error(err);
          });
      },
      motorHandler() {
        // status= 1: on, status= 2: off
        let status= this.motorOn? 2: 1;
        return this.$axios.put(`/robot/servo`, {
          input: status,
        })
          .then(res => {
            if (res.status === 200) {
              this.motorOn = !this.motorOn;
            }
          })
          .catch(err => {
            throw Error(err);
          });
      },
      offController() {
        this.showController = false;
      },
      toggleController(){
        this.showController = !this.showController;
      },
      toggleList(){
        this.showList= !this.showList;
        EventBus.$emit('toggleList');
      },
      findLocation() {
        this.showFinding = !this.showFinding;
        if (this.showFinding) {
          this.$toast('지도에서 로봇 위치를 표시하세요', {
            position: 'top-left'
          });
          EventBus.$emit('findLocation');
        } else {
          EventBus.$emit('cancelFindLocation');
        }
      },
      showControllerHandler() {
        if (!this.motorOn) {
          this.$toast('로봇 모터를 켜주세요', {
            position: 'top-left'
          });
          return;
        }
        this.showController = !this.showController
      },
      refreshApp() {
        this.$router.go(0);
      },
      getServoMotorState() {
        return this.$axios.get('/robot/servo')
          .then(res => {
            if (res.status === 200) {
              res.data.servo === 1 ? this.motorOn = true : this.motorOn = false;
            }
          })
      },
    },
  }
</script>

<style lang="scss" scoped>

.v-icon {
  font-size: 3.1rem !important;
}

.control .v-icon {
  cursor: pointer;
  font-size: 100px
}

.config .v-icon {
  cursor: pointer;
  font-size: 100px;
}

@media (max-width: 1024px) {

  .v-icon, .mdi-set, .mdi:before {
    font-size: 1.2rem !important;
  }

}

</style>
